<template>
  <div>Loading...</div>
</template>

<script>
import scriptjs from 'scriptjs'

export default {
  name: "Redirect",
  mounted() {
    scriptjs('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js');
  }
};
</script>